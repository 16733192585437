<template>
  <div
    ref="parent"
    data-test="modal-call-back"
    class="relative modal-callme d-flex white--bg"
  >
    <div
      class="modal-callme__close d-flex align-center justify-center"
      @click="$emit('close')"
    >
      <img
        loading="lazy"
        src="@/assets/images/svg/close.svg"
        alt="close"
      >
    </div>
    <div class="d-flex flex-column flex-grow">
      <p class="modal-callme__title headline-2">
        {{ title }}
      </p>
      <p class="modal-callme__subtitle title-2">
        {{ subtitle }}
      </p>
      <form
        action="#"
        autocomplete="off"
        @submit.prevent="submit"
      >
        <span class="modal-callme__label">Введите телефон</span>
        <VPhoneInput
          ref="dPhone"
          v-model:value="phoneNumber"
          class="w-100 q-modal__section"
          tabindex="1"
          @validate="checkPhoneValidation($event)"
        />
        <span class="modal-callme__label">Введите имя</span>
        <LazyVTextInput
          ref="dName"
          v-model:value="clientName"
          class="w-100 q-modal__section"
          :validation-length="0"
          enterkeyhint="send"
          tabindex="2"
        />
        <input
          class="modal-callme__fakesubmit"
          type="submit"
        >
        <LazyVMenu
          content-class="white--bg"
          @update:model-value="showActiveSortList"
        >
          <template #activator="{ props }">
            <span
              v-bind="props"
              class="modal-callme__menu pointer d-flex align-center black--text subhead-3"
            >
              Перезвоним {{ timeToCall }}
              <img
                :class="{ rotate: props['aria-expanded'] === 'true' }"
                style="transition: transform 0.24s; width: 20px; height: 20px;"
                loading="lazy"
                src="@/assets/images/svg/arrow-expand.svg"
                alt="arrow"
              >
            </span>
          </template>
          <LazyVList elevation="4">
            <LazyVListItem
              :value="timeNow"
              :disabled="timeNow.text === timeToCall"
              @click="setTimeToCall(timeNow)"
            >
              <span class="subhead-3">{{ timeNow.text }}</span>
            </LazyVListItem>
            <LazyVListItem
              v-for="item in timeList"
              :key="item.val"
              :disabled="item.text === timeToCall"
              @click="setTimeToCall(item)"
            >
              <span class="subhead-3">{{ item.text }}</span>
            </LazyVListItem>
          </LazyVList>
        </LazyVMenu>

        <d-btn
          data-test="submit"
          :loading="loading"
          :disabled="!isValidName || !isValidPhone"
          class="w-100 modal-callme__submit"
          color="primary"
          size="medium"
          @click.prevent="submit"
        >
          {{ btnTitle }}
        </d-btn>
      </form>

      <p class="subhead-6 gray-dark--text">
        Отправляя, вы соглашаетесь с
        <button
          class="secondary-dark--text"
          @click="goAway('/legal/privacy/')"
        >
          политикой обработки данных
        </button>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { createInteraction } from '~/composible/createInteraction'
import { getOrderUrl } from '~/composible/orderUrl'
import { goAway } from '~/helpers'
import { callTimeComposible } from '~/composible/callTimeComposible'

const props = defineProps({
  payments: {
    type: Array,
    default: undefined,
  },
  nextModal: {},
  provider: {},
  tariff: {},
  title: {
    type: String,
    default: 'Заявка на подбор тарифа',
  },
  subtitle: {
    type: String,
    default: 'Подберем оптимальный тариф с учетом ваших пожеланий',
  },
  btnTitle: {
    type: String,
    default: 'Отправить',
  },
  gtmLabel: {
    type: String,
    default: 'consult',
  },
})
const emit = defineEmits(['close', 'nextModal'])

const ctx = useNuxtApp()
const mainStore = useMainStore()
const $gtm = useGtm()
const phoneNumber = ref('')
const clientName = ref('')
const loading = ref(false)
const isValidName = ref(true)
const isValidPhone = ref(false)
const parent = ref()
const dPhone = ref()
const dName = ref()
const tariffsStore = useTariffs()
const houseUrl = computed(() => tariffsStore.getHouseUrl)

const {
  timeList,
  timeNow,
  timeToCall,
  callTime,
  setTimeToCall,
  showActiveSortList,
} = callTimeComposible()

const { cI } = createInteraction()

const submit = async () => {
  if (!isValidPhone.value || !isValidName.value) {
    dPhone.value.onBlur()
    dName.value.onBlur()
    return
  }
  loading.value = true

  const meta = {
    request_name: props.gtmLabel,
  }

  const lead = {
    phone: '+7' + phoneNumber.value.replace(/[\-(\s)]/g, ''),
    first_name: clientName.value,
    call_time: callTime.value,
  }

  tariffsStore.$patch({
    clientPhone: '+7' + phoneNumber.value,
    clientName: clientName.value,
    timeToCall: timeToCall.value,
  })
  const tariffS = {
    provider_id: props.provider?.id || props.tariff?.provider?.id,
    tariff_id: props.tariff?.id,
    fake_mobile: props.tariff?.markers?.fakeMobile,
    fake: props.tariff?.markers?.fake,
    tariff_name: props.tariff?.name,
    device_payment_ids: props.payments,
  }

  const ciResp = await cI(
    !!houseUrl.value && !props.nextModal,
    lead,
    tariffS,
    meta,
    undefined,
  )

  $gtm?.trackEvent({
    event: 'trackEvent',
    category: 'form',
    action: 'success',
    label: props.gtmLabel,
  })

  tariffsStore.$patch({
    orderId: ciResp,
    interactionId: ciResp,
  })
  const ciRespCoockie = useCookie<any>('ciResp')
  ciRespCoockie.value = ciResp

  if (props.nextModal) {
    emit('nextModal')
  }
  else {
    getOrderUrl()

    emit('close')
  }
}

const checkPhoneValidation = (event: any) => {
  isValidPhone.value = event

  if (event) {
    nextTick(() => {
      dName.value.inputRef.focus()
    })
  }
}
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}

onBeforeMount(async () => {
  console.log('CallMe modal opened')
  ctx?.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'form',
    action: 'open',
    label: props.gtmLabel,
  })
  await mainStore.fetchCallSlots()
  timeToCall.value = timeNow.value.text
})
onMounted(() => {
  nextTick(() => {
    if (dPhone.value?.inputRef) dPhone.value.inputRef.focus()
  })
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})
</script>

<style scoped lang="scss">
.modal-callme {
  width: 580px;
  padding: 40px 40px 12px;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 16px 16px 12px;
    border-radius: 0;
    height: 100%;
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: color(gray-darker);
    img {
      opacity: .7;
      transition: opacity .24s;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  &:deep(.t-input), &:deep(.p-input) {
    background-color: color(gray-p);
  }
  &__menu {
    margin-right: auto;
    margin-bottom: 16px;
  }
  &__overtitle {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 12px;
    }
  }
  &__title {
    margin-bottom: 4px;
  }
  &__subtitle {
    margin-bottom: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 20px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 16px;
    }
  }

  &__fakesubmit {
    max-height: 0 !important;
    overflow: hidden !important;
    opacity: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
  }

  &__submit {
    margin-bottom: 24px;
    height: 56px;
    @media (max-width: getBreakpoint(tablet)) {
      height: 44px;
      margin-bottom: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }

  &__after {
    cursor: pointer;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    .d-icon {
      margin-right: 4px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    span {
      margin-right: auto;
    }
  }

  &.p-color {
    .modal-callme__submit:not(:disabled) {
      background: var(--mainColor);
      color: var(--font);
    }
  }

  @media (max-width: getBreakpoint(tablet)) {
    flex-wrap: wrap;
    width: 100%;
  }
}
</style>
